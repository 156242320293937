import { Palette, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Card, CircularProgress, Grid, 
    IconButton, InputAdornment, Link, List,
    ListItem, ListItemText, Menu, MenuItem, Popover,
    Toolbar, Zoom, ListItemIcon, ListItemButton,
    TextField, Typography, Tooltip, ToggleButtonGroup, ToggleButton, FormControl, InputLabel, FormHelperText, Input } 
from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuctionLoader from '../common/auction-loader/AuctionLoader';
import AuctionReCaptcha from '../common/AuctionReCaptcha';
import AuctionSnackBar from '../common/AuctionSnackBar';
import { ThemeContext } from '../common/context/ThemeContext';
import { UserContext } from '../common/context/UserContext';
import * as Validators from '../common/form-validators.js';
import { ContentBox, IMG, JustifyBox, RootDiv } from './AuthStyle';
import CompleteNewPassword from './new-password/CompleteNewPassword';
import PoweredBy from './PoweredBy';
import { AuctionStatus, AppHostDomain } from '../common/StaticVariableDeclaration';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { Loop } from '@mui/icons-material';
import { PublicAxiosAuctionInterceptor, AuthAxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import MFAVerification from './mfa-verification/MFAVerification';
import MuiPhoneNumber from "material-ui-phone-number";
import {MdAttachEmail} from "react-icons/md"
import {FaMobileAlt} from "react-icons/fa"
import { makeStyles } from '@mui/styles';
import { HomePageLink } from '../home/HomePageLink';   
import { MdHowToVote } from "react-icons/md";
import { BsBank2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';


const useStyles = makeStyles(() => ({
    container: {
        fontWeight: "bold !important", 
        fontSize: '12px !important',
        paddingRight: '1em !important'
    },
    EmailColor:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "50px 0px 0px 50px !important",
    },
    mobileNumber:{
        background: '#1976d2 !important',
        color: 'white !important',
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultMobileColor:{
        borderRadius: "0px 50px 50px 0px !important",
    },
    defaultEmailColor:{
        borderRadius: "50px 0px 0px 50px !important",
    }
    
}))

const Login = (props) => {
    const { getUser } = props;
    const classes = useStyles();
    let history = useHistory();
    const { t } = useTranslation();
    const context = useContext(UserContext);
    const contextValue = useContext(UserContext).rootContext;
    const themeContext = useContext(ThemeContext);
    const [userDetails, setUserDetails] = useState({
        username: '',
        password: '',
        phonenumber:''
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [resetPassCaptcha, setResetPassCaptcha] = useState('');
    const [isRefreshResetPassCaptcha, setIsRefreshResetPassCaptcha] = useState(false);
    const [cognitoUser, setCognitoUser] = useState();
    const [showCompleteNewPassword, setShowCompleteNewPassword] = useState(false);
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);
    const [showMFAModel, setShowMFAModel] = useState(false);
    const [extension, setExtension] = useState('+91')
    const [value, setValue] = useState('email')
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenuLink = Boolean(anchorElMenu);
    const menuLinkId = openMenuLink ? 'link-menu-popover' : undefined

    const handleMenuClick = (event) => {
        setAnchorElMenu(event.currentTarget);
    }

    const handleMenulinkClose = () => {
        setAnchorElMenu(null);
    }
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const validate = {
        username: username => Validators.emailValidation(username),
        phonenumber: phonenumber => Validators.mobileNumberValidation(extension + phonenumber)
        // password: password => Validators.requiredValidation('password', password)
    }

    const onInputChange = (event) => {
        setUserDetails(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    const onInputEmailChange = event => {
        let value = event.target.value.trim();
        setUserDetails(prevState => ({
            ...prevState,
            [event.target.id]: value !== '' ? value.toLowerCase() : ''
        }));
    }

    const updateValidators = () => {
        validateForm().then(data => {
            if (Object.values(data.errors).length !== 0) {
                setLoading(false)
            }
        })
    }

    const validateForm = async () => {
        setErrors(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(userDetails).map(([key]) => {
            if (value === 'email' ? key === 'username' : key === 'phonenumber') {
                const newError = validate[key](userDetails[key])
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        setErrors(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    const handleSubmit = event => {
        event.preventDefault();
        // if(/^[0-9+]+$/.test(userDetails.username)) {
        //     setErrors(prevState => ({
        //         ...prevState,
        //         errors: {}
        //     }));
        //     checkMFA();
        // } else {
            validateForm().then(data => {
                if (Object.values(data.errors).length === 0) {
                    // signIn();
                    checkMFA();
                } else {
                    // setLoading(false);
                }
            })
        // }
        // checkMFA();
        // signIn();
    }

    const checkMFA = () => {
        let payload = {
            loginId:value === 'mobile' ? extension + userDetails.phonenumber : userDetails.username
        }
        setLoading(true);
        setMessage({showMsg: false, message: '', severity: ''});
        AuthAxiosAuctionInterceptor.post(`mfa/?recaptcha=${captcha}`, payload).then(response => {
            setIsMFAEnabled(response);
            setShowMFAModel(true);
            // if (response) {
            //     setIsMFAEnabled(response);
            // } else {
            //     signIn();
            // }
            setLoading(false);
        }).catch(error => {
            refreshCaptcha();
            refreshRefreshPassCaptcha();
            if (error.message) {
                setMessage({showMsg: true, message: error.message, severity: 'error'});
            } else {
                setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const signIn = async () => {
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null });
        let payload = {
            userName: userDetails.username,
            password: userDetails.password
        }
        let userInfo = null;
        if (captcha != '') {
            PublicAxiosAuctionInterceptor.post(`login?recaptcha=${resetPassCaptcha}`, payload).then(response => {
                setLoading(false);
                userInfo = decodeToken(response.access_token);
                sessionStorage.setItem('token', response.access_token);
                sessionStorage.setItem('refreshToken', response.refresh_token);
                sessionStorage.setItem('isMfaEnabled', userInfo.isMfaEnabled);
                getUser();
                setLoading(true)
                context.setRootContext(prevState => ({
                    ...prevState,
                    userSession: {
                        isAuthenticated: true, session: {
                            accessToken: response.access_token,
                            refreshToken: response.refresh_token,
                            name: userInfo.firstName,
                            middleName: userInfo.middleName !== undefined && userInfo.middleName !== null ? userInfo.middleName : '',
                            lastName: userInfo.lastName !== undefined && userInfo.lastName !== null ? userInfo.lastName : '',
                            dateOfBirth: userInfo.dateOfBirth !== undefined ? userInfo.dateOfBirth : null,
                            individual: userInfo.individual,
                            email: userInfo.email,
                            phoneNumber: userInfo.phoneNumber,
                            userId: userInfo.sub
                        },
                        roles: {
                            agent: true,
                            auctioneer: true
                        }
                    },
                    loginUserRoles: userInfo.roles,
                    currentDateTime: null,
                    selectedMenu: 'auction'
                    })
                )
            }).catch((error) => {
                refreshCaptcha();
                refreshRefreshPassCaptcha();
                setTimeout(() => {
                    if (error.message) {
                        if (error.message.includes('Need to reset password') || error.message.includes('User credentials have expired')) {
                            // sendOTPToResetPassword();
                            history.push({
                                pathname: "/auto-account-password-change",
                                state: { username: userDetails.username }
                            })
                        } else {
                            setMessage({showMsg: true, message: error.message, severity: 'error'});
                        }
                    } else {
                        setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
                    }
                    setLoading(false);
                }, 2000);
            })
        } else {
            refreshCaptcha();
            refreshRefreshPassCaptcha();
            setMessage({ showMsg: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ showMsg: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    const decodeToken = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const getUserInformation = (userId) => {
        if (userId != null) {
            AuthAxiosAuctionInterceptor.get(`user/get-account/${userId}`).then(response => {
                context.setUserSession(prevState => ({
                    ...prevState,
                    isAuthenticated: true, session: {
                        ...prevState.session,
                        name: response.firstName,
                        email: response.email,
                        phone_number: response.phoneNumber,
                        userId: response.loginUuid
                    }
                }));
            }).catch(error => {
                if (error.message) {
                    setMessage({showMsg: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
                }
            })
        }
    }

    // const sendOTPToResetPassword = () => {
    //     setLoading(true);
    //     AuthAxiosAuctionInterceptor.patch(`user/request-otp?recaptcha=${resetPassCaptcha}&purpose=reset-password`, {
    //         loginId: userDetails.username
    //     }).then(response => {
    //         if (response) {
    //             history.push({
    //                 pathname: "/reset-password", state: {
    //                     phone: '',
    //                     username: userDetails.username
    //                 }
    //             })
    //         }
    //         setLoading(false)
    //     }).catch((error) => {
    //         setLoading(false);
    //         refreshRefreshPassCaptcha();
    //         if (error.message) {
    //             setMessage({showMsg: true, message: error.message, severity: 'error'});
    //         } else {
    //             setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
    //         }
    //     })
    // }

    const handleClose = () => {
        setShowCompleteNewPassword(false);
        refreshCaptcha()
    }

    const goToLandingPage = () => {
        // console.log('from signin to .......ToLandingPage......')
        // **************  Uncomment for isolated landing component ******************
        // theme.dispatch({ type: 'light' });
        // history.push({ pathname: "/landing" })
        history.push({
            pathname: "/auction-list",
            state: {
                userRole: [],
                auctionListType: AuctionStatus.open,
                pagination: {
                    page: 1,
                    pageSize: 10
                },
                isMyBid: false
            }
        })
    }

    useEffect(() => {
        if (contextValue.userSession.isAuthenticated) {
            goToLandingPage();
        }
    }, [])

    // const [getUserDetails] = useLazyQuery(GET_USER_DETAILS, {
    //     errorPolicy: 'all', fetchPolicy: 'network-only',
    //     nextFetchPolicy: 'cache-first',
    //     notifyOnNetworkStatusChange: true,
    //     onCompleted: (data) => {
    //         console.log("data", data);
    //         themeContext.dispatch({ type: data?.getUserSetting?.theme })
    //         setLoginUserRoles
    //     },
    //     onError: (error) => {
    //         console.log(error);
    //     },
    //     caches: (data) => {
    //         console.log(data);
    //     }
    // })

    // const [getUserDetails, { loading: getUserLoading, error: getUserError } ] = useLazyQuery(GET_MY_SETTINGS, {
    //     errorPolicy: 'all', fetchPolicy: 'network-only',
    //     nextFetchPolicy: 'cache-first',
    //     notifyOnNetworkStatusChange: true,
    //     onCompleted: (data) => {
    //         console.log("getUserDetails inside login.js", data);
    //         context.setLoginUserRoles(data?.getMySetting?.systemRole);
    //         themeContext.dispatch({ type: data?.getUserSetting?.theme })
    //     },
    //     onError: (error) => {
    //         console.log(error);
    //     },
    //     caches: (data) => {
    //         console.log(data);
    //     }
    // })

    const forgotPasssword = () => {
        history.push("/forgot-password")
    }

    const verifyUser = () => {
        history.push("/sign-up/verify")
    }

    const signUp = () => {
        history.push("/sign-up");
    }

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const refreshRefreshPassCaptcha = () => {
        setIsRefreshResetPassCaptcha(true);
        setTimeout(() => {
            setIsRefreshResetPassCaptcha(false);
        }, 1000);
    }

    const onCancelMFAVerification = () => {
        setIsMFAEnabled(false);
        setShowMFAModel(false);
        setUserDetails({
            username: '',
            password: ''
        })
    }

    const handleLoginDetails = (event, newValue) => {
        setErrors('')
        setUserDetails({
          username:'',
          phonenumber:''
        })
        setValue(newValue === null ? value : newValue)
    }

    const onInputMobileNumberChange =(event) =>{
        if (event.target.value === '' || /^[0-9]+$/.test(event.target.value)) {
            setUserDetails(prev => ({
                ...prev,
                phonenumber: event.target.value
            }))
        } else if (event.target.value.length === 0) {
            setUserDetails(prev => ({
                ...prev,
                phonenumber: ''
            }))
        }
    }

    const onMobileNumberChange = (value) =>{
        setExtension(value)
      }

    return (
        <Fragment>
        {!showMFAModel && <RootDiv>
                <div className="card-buttons">
                    {/* <Tooltip title={t("Navigate_E_Voting_Platform")} arrow>
                        <Button size="small" variant="contained" color="primary">
                            <Link
                                target="_blank"
                                href={process.env.REACT_APP_VOTING}
                                style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: "white",
                                    display: "flex",
                                    gap: "8px",
                                    textTransform: "capitalize",
                                }}
                            >
                                <MdHowToVote size="20px" />
                                e-voting
                            </Link>
                        </Button>
                    </Tooltip>
                    <Tooltip title={t("Navigate_ICMS_Platform")} arrow>
                        <Button size="small" variant="contained" color="primary">
                            <Link
                                target="_blank"
                                href={process.env.REACT_APP_ICMS}
                                style={{
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                <BsBank2 size="20px" />
                                ICMS
                            </Link>
                        </Button>
                    </Tooltip> */}
                    <Tooltip title={t('Switch_to')}>
                        <IconButton aria-label="menu" onClick={handleMenuClick} style={{ right: '10px', padding: '3px' }}>
                            <CgMenuGridO fontSize='30px' />
                        </IconButton>
                    </Tooltip>
                    <Popover
                          id={menuLinkId}
                          open={openMenuLink}
                          anchorEl={anchorElMenu}
                          onClose={handleMenulinkClose}
                          PaperProps={{
                              style: { width: '20%' },
                          }}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                          }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="switch">
                                    <ListItem>
                                        <ListItemText id="switch-to"
                                            primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('Switch_to')}</Typography>} />
                                    </ListItem>
                                    <Link target="_blank" href={process.env.REACT_APP_EVOTING_URL} style={{ textDecoration: 'none' }} >
                                        <ListItemButton key={1} id="link-to-evoting">
                                            <img id="menuIcon" height={45} width={150} src={process.env.PUBLIC_URL + './images/evote-logo.svg'} alt='TRUSTeVote' />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="switch">
                                    <Link target="_blank" href={process.env.REACT_APP_ICMS} style={{ textDecoration: 'none', color: '#317590' }} >
                                        <ListItemButton key={1} id="link-to-icms">
                                            <ListItemText id="switch-to"
                                                primary={<Typography variant="h4" style={{ fontWeight: 'bold', marginLeft: '20px' }}>{t('ICMS')}</Typography>} />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                        </Grid>
                    </Popover>
                </div>

            <Card className='card'>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                        { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                            <JustifyBox p={4} height="100%">
                                <IMG
                                    src={process.env.PUBLIC_URL + '/login.svg'}
                                    alt=""
                                />
                            </JustifyBox>
                        }
                        { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                            <JustifyBox p={4} height="20%" style={{ alignItems: 'baseline' }}>
                                <img src={process.env.PUBLIC_URL + './images/nesl-pda.png'} height={50} alt='NeslPDALogo'></img>
                            </JustifyBox>
                        }
                        { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid &&
                            <JustifyBox p={4} height="80%" style={{ alignItems: 'baseline' }}>
                                <IMG
                                    src={process.env.PUBLIC_URL + '/login.svg'}
                                    alt=""
                                />
                            </JustifyBox>
                        }       
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <ContentBox>
                            <Grid item xs={12} className="text-center" sx={{ marginLeft: '40px' }}>
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + './images/auction.svg'} height={50} alt='E-AuctionLogo'></img> }
                                { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nbid.png'} height={45} alt='NbidLogo'></img>}
                                <HomePageLink />
                            </Grid>
                            <br />
                            <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                {t('Sign_In')}
                            </Typography>
                            <Grid item xs={12} align="center" style={{ marginTop: "1.5rem", marginBottom:'1.5rem' }}>
                                  <ToggleButtonGroup
                                    value={value}
                                    exclusive
                                    size="small"
                                    onChange={handleLoginDetails}
                                    aria-label="text alignment"
                                  >
                                    <Tooltip title="Login using Email">
                                      <ToggleButton
                                        value="email"
                                        aria-label="left aligned"
                                        className={`${classes.container} ${value === "email" ? classes.EmailColor : classes.defaultEmailColor}`}
                                      >
                                        <MdAttachEmail fontSize='large'/>
                                        &nbsp; email
                                      </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Login using Mobile Number">
                                      <ToggleButton
                                        value="mobile"
                                        aria-label="centered"
                                        className={ `${classes.container} ${value == 'mobile' ? classes.mobileNumber : classes.defaultMobileColor}`}
                                      >
                                        <FaMobileAlt fontSize='large'/> Mobile Number
                                      </ToggleButton>
                                    </Tooltip>
                                  </ToggleButtonGroup>
                                </Grid>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                    {value === "mobile" ? (
                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                    style={{margin: "8px 8px 8px 0px"}}>
                                        <InputLabel htmlFor="phonenumber">
                                            {t('Username_Mobile_Number')}
                                        </InputLabel>
                                      <Input
                                        id="phonenumber"
                                        name="phonenumber"
                                        value={userDetails.phonenumber}
                                        // helperText={errors['phonenumber']}
                                        error={errors !== "" && errors.errors.phonenumber}
                                        onChange={onInputMobileNumberChange}
                                        startAdornment={
                                          <InputAdornment
                                            position="start"
                                            style={{ width: extension.length > 3 ? extension.length === 4 ? '6.5rem' : extension.length === 5 ? '7.8rem' : '10.5rem' : '5.5rem' }}
                                            >
                                            <MuiPhoneNumber
                                              id="input-with-icon-adornment"
                                              name="extension"
                                              defaultCountry={"in"}
                                              value={extension}
                                              // helperText={errors !== '' && errors.errors.username}
                                              // error={errors !== '' && errors.errors.username}
                                              onChange={(event) => onMobileNumberChange(event)}
                                              autoFormat={false}
                                              style={{margin: "8px 0px", color: "black !important"}}
                                              required
                                              disableDropdown={loading}
                                              countryCodeEditable={false}
                                              disabled
                                            />
                                          </InputAdornment>
                                        }
                                        disabled={loading}
                                        autoComplete="new-password"
                                        aria-describedby="phonenumber-helper-text"
                                      />
                                      <FormHelperText
                                        id="phonenumber-helper-text"
                                        style={{ color: "red" }}
                                      >
                                        {errors !== "" && errors.errors.phonenumber}
                                      </FormHelperText>
                                    </FormControl>
                                  ) : (
                                    <TextField id="username" label={t('Username_Email')} style={{ margin: '8px 8px 8px 0px' }}
                                            fullWidth value={userDetails.username}
                                            onChange={onInputEmailChange}
                                            // error={errors['username'] !== undefined}
                                            variant='standard'
                                            autoFocus
                                            helperText={errors !== '' && errors.errors.username}
                                            // autoComplete="new-password"
                                            disabled={loading} autoComplete="off"
                                        />
                                  )}
                                        {/* <TextField id="username" label={t('Username_Email_Cellphone')} style={{ margin: '8px 8px 8px 0px' }}
                                            fullWidth value={userDetails.username}
                                            onChange={onInputEmailChange}
                                            // error={errors['username'] !== undefined}
                                            variant='standard'
                                            helperText={errors !== '' && errors.errors.username}
                                            // autoComplete="new-password"
                                            disabled={loading} autoComplete="off"
                                        /> */}
                                        {/* <TextField id="password" label={t('Password')} type={showPassword ? "text" : "password"}
                                            style={{ margin: '8px 8px 8px 0px' }} fullWidth value={userDetails.password}
                                            onChange={onInputChange} variant='standard'
                                            // error={errors['password'] !== undefined}
                                            helperText={errors !== '' && errors.errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            id='eyepwdvisibility'
                                                            aria-label="toggle password visibility"
                                                            onClick={toggleShowPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            autoComplete="off" disabled={loading}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Typography variant="body2">
                                            <Link id="forgotlink" onClick={forgotPasssword} className="pointer">{t('Forgot_Password_?')}</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Typography variant="body2">
                                            {t('Not_Verified_?')} <Link id="verifylink" onClick={verifyUser} className="pointer">{t('Verify')}</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" id="signin" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                            disabled={!(userDetails.username || userDetails.phonenumber)}>
                                            {t('Continue')}
                                            {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="center">
                                        <Typography variant="body2">
                                            {t("Don't_have_an_account_?")} <Link id="signup" onClick={signUp} className="pointer">{t('Sign_Up')}</Link>
                                        </Typography>
                                    </Grid>
                                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle &&
                                        <Grid item xs={12} container marginTop="10px" justifyContent="center">
                                            <PoweredBy />
                                        </Grid>
                                    }
                                </Grid>
                            </form>
                        </ContentBox>
                    </Grid>
                </Grid>
            </Card>
            {showCompleteNewPassword && <CompleteNewPassword open={showCompleteNewPassword} user={cognitoUser}
                password={userDetails.password} getUser={getUser} handleClose={handleClose} />}
            {message.showMsg && <AuctionSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}>
            </AuctionSnackBar>}
            {(loading) && <AuctionLoader show={loading} />}
            {!isRefresh && <AuctionReCaptcha key="login" captcha={setCaptcha} refresh={setIsRefresh} />}
            {!isRefreshResetPassCaptcha && <AuctionReCaptcha key="reset-password" captcha={setResetPassCaptcha} refresh={setIsRefreshResetPassCaptcha} />}
        </RootDiv>}
        {showMFAModel && <MFAVerification onCancel={onCancelMFAVerification} username={value === 'mobile' ? extension + userDetails.phonenumber : userDetails.username}
            password={userDetails.password} getUser={getUser} isMFAEnabled={isMFAEnabled}/>}
        </Fragment>
    )
}

export default Login;